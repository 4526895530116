



































import Vue from "vue";
import firebase from "@/plugins/firebase";

export default Vue.extend({
  metaInfo: {
    title: "Parolamı unuttum"
  },
  data: () => ({
    form: {
      email: ""
    },
    alert: false,
    message: "",
    error: false
  }),
  computed: {
    emailRules() {
      return [
        (v: string) => !!v || "Lütfen e-postanızı yazınız",
        (v: string) => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
      ];
    }
  },
  methods: {
    validate() {
      const form = this.$refs.form as HTMLFormElement;
      return form.validate();
    },
    reset() {
      const form = this.$refs.form as HTMLFormElement;
      form.reset();
    },
    resetValidation() {
      const form = this.$refs.form as HTMLFormElement;
      form.resetValidation();
    },
    async send() {
      if (this.validate()) {
        try {
          // Kurumsal kullanıcı mı?
          const userQs = await firebase
            .firestore()
            .collection("users")
            .where("email", "==", this.form.email)
            .get();

          if (!userQs.empty) {
            // Kullanıcı adı ve parolası ile giriş yap
            await firebase.auth().sendPasswordResetEmail(this.form.email);

            this.error = false;
            this.alert = true;
            this.message =
              "Eğer belirttiğiniz adres sistemimizde kayıtlı ise parola sıfırlama e-postası gönderilecektir.";
          }
        } catch {
          this.error = true;
          this.alert = true;
          this.message = "Parola sıfırlama e-postası gönderilemedi!";
        }
      }
    }
  },
  mounted() {
    this.$emit("setTitle", "Parolamı unuttum");
  }
});
